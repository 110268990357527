import "./Knowlege.scss";
import Label from "../components/Label";
import knowledges from "../data/knowledges.json";
import { ReactComponent as KnowledgeLogo } from "../svg/knowledge.svg";
import { ReactComponent as ProgrammingLogo } from "../svg/programming.svg";

function Knowledge() {
  return (
    <div className="section" id="knowledge">
      <div className="header pb-10">
        <KnowledgeLogo />
        Frameworks
      </div>
      {knowledges.framekworks.map((keys, i) => {
        return (
          <div
            className="content flex-row flex-justify"
            key={i + "knowldgeSection"}
          >
            {keys.map((key) => {
              return <Label text={key} key={key} />;
            })}
          </div>
        );
      })}
      <div className="header pb-10 pt-40">
        <ProgrammingLogo />
        Programming
      </div>
      {knowledges.programming.map((keys, i) => {
        return (
          <div className="content flex-row" key={i + "programmingSection"}>
            {keys.map((key) => {
              return <Label text={key} key={key} />;
            })}
          </div>
        );
      })}
    </div>
  );
}

export default Knowledge;
