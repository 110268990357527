import "./Jobs.scss";
import jobs from "../data/jobs.json";
import Label from "../components/Label";
import { ReactComponent as ExperienceLogo } from "../svg/experience.svg";

const Job = ({ job }) => {
  const {
    period,
    logo,
    logoBackground,
    role,
    company,
    description,
    topics,
    keywords,
  } = job;

  return (
    <div className="col pt-40" key={company}>
      <div className="content-period pt-1">
        {period}
        {logo ? (
          <img
            className={
              logoBackground
                ? "company-logo company-logo-background"
                : "company-logo"
            }
            alt={company}
            src={logo}
          />
        ) : (
          ""
        )}
      </div>
      <div className="content">
        <div className="col flex-row flex-mobile-column space-between pb-10">
          <div className="text-bold text-large">{role}</div>
          <div className="text-bold pt-1">@ {company}</div>
        </div>
        <div className="col content">{description}</div>
        <div className="col content">
          <ul>
            {topics?.map((topic, index) => (
              <li key={index}>{topic}</li>
            ))}
          </ul>
        </div>
        <div className="col flex-row">
          {keywords.map((keyword) => (
            <Label text={keyword} key={keyword} />
          ))}
        </div>
      </div>
    </div>
  );
};

const Jobs = () => {
  return (
    <div className="bg-gray" id="jobs">
      <div className="section">
        <div className="header">
          <ExperienceLogo />
          Experience
        </div>
        {jobs.map((job) => (
          <Job job={job} key={job.company} />
        ))}
      </div>
    </div>
  );
};

export default Jobs;
