const Imprint = () => {
  return (
    <div className="bg-gray">
      <div className="section">
        <div className="header">Impressum</div>
        <div className="content">
          <p>
            <strong>Angaben gemäß § 5 TMG</strong> <br />
            Nils Ernsting <br />
            Erikastraße 87 <br />
            20251 Hamburg <br />
          </p>
          <p>
            <strong>Kontakt</strong> <br />
            Telefon: +49 179 3249382
            <br />
            E-Mail: <a href="mailto:nils@ernsting.io">nils@ernsting.io</a>
            <br />
          </p>
          <p>
            <strong>Umsatzsteuer-ID </strong> <br />
            Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz:
            DE334927300
          </p>
        </div>
      </div>
    </div>
  );
};

export default Imprint;
