function Knowledge() {
  return (
    <div className="section">
      <center>
        <h3>404</h3>
        <p>Sorry, the page you are looking for does not exist.</p>
      </center>
    </div>
  );
}

export default Knowledge;
