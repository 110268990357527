import "./Header.scss";
import React, { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { HashLink } from "react-router-hash-link";
import DarkModeToggle from "../components/DarkModeToggle";
import { ReactComponent as Logo } from "../svg/logo.svg";
import { ReactComponent as BurgerMenu } from "../svg/burger-menu.svg";
import { ReactComponent as CrossMenu } from "../svg/cross.svg";
import { ReactComponent as Person } from "../svg/person.svg";
import { ReactComponent as Experience } from "../svg/experience.svg";
import { ReactComponent as Knowledge } from "../svg/knowledge.svg";
import { ReactComponent as Education } from "../svg/education.svg";
import { ReactComponent as Contact } from "../svg/contact.svg";
import { ReactComponent as Info } from "../svg/info.svg";
import { ReactComponent as Download } from "../svg/download.svg";

function Header() {
  const [isOpen, setOpen] = useState(false);

  const handleIsOpen = () => {
    setOpen(!isOpen);
  };

  const closeSideBar = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="section print-header">
        <p className="align-center">
          <strong>nils</strong>ernsting
        </p>
      </div>
      <header className="nav non-print">
        <nav className="nav__container">
          <div className="headline">
            <HashLink to="/#top">
              <Logo /> <strong>nils</strong>ernsting
            </HashLink>
          </div>
          <div className="nav__items">
            <HashLink className="non-mobile" offset={-60} to="/#knowledge">
              Skills
            </HashLink>
            <HashLink className="non-mobile" offset={-60} to="/#jobs">
              Experience
            </HashLink>
            <HashLink className="non-mobile" offset={-60} to="/#education">
              Education
            </HashLink>
            <HashLink className="non-mobile" offset={-60} to="/#contact">
              Contact
            </HashLink>
          </div>
          <div>
            <DarkModeToggle />
          </div>
          <div className="non-mobile">
            <a href="assets/CV_Nils_Ernsting.pdf" title="Download CV as pdf">
              <Download />
            </a>
          </div>
          <div className="mobile mobile-menu">
            <Menu
              customBurgerIcon={<BurgerMenu />}
              customCrossIcon={<CrossMenu />}
              width={"100%"}
              noOverlay={true}
              isOpen={isOpen}
              onOpen={handleIsOpen}
              onClose={handleIsOpen}
              right
            >
              <HashLink
                className="menu-item"
                onClick={closeSideBar}
                offset={-60}
                to="/#aboutMe"
              >
                <Person />
                About me
              </HashLink>
              <HashLink
                className="menu-item"
                onClick={closeSideBar}
                offset={-60}
                to="/#knowledge"
              >
                <Knowledge />
                Skills
              </HashLink>
              <HashLink
                className="menu-item"
                onClick={closeSideBar}
                offset={-60}
                to="/#jobs"
              >
                <Experience /> Experience
              </HashLink>
              <HashLink
                onClick={closeSideBar}
                className="menu-item"
                offset={-60}
                to="/#education"
              >
                <Education />
                Education
              </HashLink>
              <HashLink
                className="menu-item"
                onClick={closeSideBar}
                offset={-60}
                to="/#contact"
              >
                <Contact />
                Contact
              </HashLink>
              <HashLink
                onClick={closeSideBar}
                className="menu-item"
                offset={-60}
                to="/imprint#top"
              >
                <Info />
                Imprint
              </HashLink>
              <a href="assets/CV_Nils_Ernsting.pdf" className="pt-10 menu-item">
                <Download />
                Download as PDF
              </a>
            </Menu>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;
