import "./AboutMe.scss";
import { ReactComponent as Person } from "../svg/person.svg";

function AboutMe() {
  return (
    <>
      <div className="section">
        <div className="content space-center flex-row center-items pb-10">
          <img
            src="img/Nils_Ernsting.png"
            alt="Nils Ernsting"
            className="head"
          />
          <div className="name non-print">
            <span className="title">
              <strong>nils</strong>ernsting
            </span>
            <br />
            Software Enthusiast
          </div>
        </div>
      </div>
      <div className="bg-gray" id="aboutMe">
        <div className="section">
          <div className="header pb-10">
            <Person />
            About me
          </div>
          <div className="content">
            <p>
              Nils is described as a person with clear goals and a focused
              mindset. He takes responsibility where necessary and significantly
              contributes to developing the right solution.
            </p>
            <p>
              He is able to quickly adapt to complex issues. With both a
              technical and generalist background, he is able to outline
              approaches to solutions and identify potential risks at an early
              stage. To do so, he uses his methodical skills to correctly
              capture requirements.
            </p>
            <p>
              He validates assumptions based on feedback from users as well as
              stakeholders in order to reduce risks. He also investigates
              existing data sources to reject hypotheses early on if necessary
              or to make the success of initiatives visible.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutMe;
