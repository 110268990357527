import React, { useContext } from "react";
import { ThemeContext } from "../provider/ThemeProvider";
//import { ReactComponent as DarkModeLogo } from "../svg/dark-mode.svg";
import { ReactComponent as Sun } from "../svg/sun.svg";
import { ReactComponent as Moon } from "../svg/moon.svg";

function DarkModeToggle() {
  const { dark, toggle } = useContext(ThemeContext);
  if (dark) {
    return <Sun onClick={toggle} title="Light mode" />;
  }
  return <Moon onClick={toggle} title="Dark mode" />;
}

export default DarkModeToggle;
