import "./Footer.scss";
import { HashLink } from "react-router-hash-link";
import { ReactComponent as Download } from "../svg/download.svg";
import { ReactComponent as Info } from "../svg/info.svg";

function Footer() {
  const now = new Date();
  return (
    <div className="section" name="footer">
      <div className="align-center pt-20 non-print">
        <a href="/assets/CV_Nils_Ernsting.pdf">
          <Download />
          Download as PDF
        </a>
        <span className="separator">|</span>
        <HashLink to="/imprint#top">
          <Info />
          Imprint
        </HashLink>
      </div>
      <p className="align-center">
        Copyright &copy; {now.getFullYear()} Nils Ernsting
      </p>
    </div>
  );
}

export default Footer;
