import Knowledge from "./Knowledge";
import Jobs from "./Jobs.js";
import Education from "./Education";
import AboutMe from "./AboutMe";
import Contact from "./Contact";

const Home = () => {
  return (
    <>
      <AboutMe />
      <Knowledge />
      <Jobs />
      <Education />
      <Contact />
    </>
  );
};

export default Home;
