import React, { useLayoutEffect } from "react";
import { useDarkMode } from "usehooks-ts";

export const ThemeContext = React.createContext({
  dark: false,
  toggle: () => {},
});

export default function ThemeProvider({ children }) {
  const { isDarkMode, enable, disable } = useDarkMode();

  // paints the app before it renders elements
  useLayoutEffect(() => {
    // Media Hook to check what theme user prefers
    applyTheme();
    // if state changes, repaints the app
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDarkMode]);

  // rewrites set of css variablels/colors
  const applyTheme = () => {
    let theme;
    if (isDarkMode) {
      theme = darkTheme;
    } else {
      theme = lightTheme;
    }

    const root = document.getElementsByTagName("html")[0];
    root.style.cssText = theme.join(";");
  };

  const toggle = () => {
    // A smooth transition on theme switch
    const body = document.getElementsByTagName("body")[0];
    body.style.cssText = "transition: background .5s ease";

    Array.from(document.getElementsByClassName("bg-gray")).forEach(
      (element) => {
        element.style.cssText = "transition: background .5s ease";
      },
    );

    Array.from(document.getElementsByClassName("nav")).forEach((element) => {
      element.style.cssText = "transition: background .5s ease";
    });

    if (isDarkMode) {
      disable();
    } else {
      enable();
    }
  };

  return (
    <ThemeContext.Provider value={{ dark: isDarkMode, toggle: toggle }}>
      {children}
    </ThemeContext.Provider>
  );
}

// styles
const lightTheme = [
  "--primary-color: #121212",
  "--secondary-color: #777777",
  "--background-color: #FFFFFF",
  "--inverted-color: #FFFFFF",
  "--light-color: #DDDDDD",
  "--highlight-color: #5289A5",
  "--gray-color: #EEEEEE",
  "--image-background: #FFFFFF",
];

const darkTheme = [
  "--primary-color: #EEEEEE",
  "--secondary-color: #888888",
  "--background-color: #333333",
  "--light-color: #3F3F3F",
  "--highlight-color: #5289A5",
  "--gray-color: #222222",
  "--image-background: rgba(255,255,255,0.8)",
];
