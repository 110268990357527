import "./Contact.scss";
import { ReactComponent as ContactLogo } from "../svg/contact.svg";
import { ReactComponent as VCard } from "../svg/vcard.svg";
import { ReactComponent as LinkedIn } from "../svg/linkedin.svg";
import { ReactComponent as Github } from "../svg/github.svg";

function ContactImprint() {
  return (
    <div className="bg-gray">
      <div className="section" id="contact">
        <div className="header pb-10">
          <ContactLogo />
          Contact
        </div>
        <div className="content flex-row space-around">
          <div className="contact-item">
            <span>Phone</span>
            <div>
              <a href="tel:+491793249382">+49 179 3249382</a>
            </div>
          </div>
          <div className="contact-item">
            <span>Mail</span>
            <div>
              <a href="mailto:nils@ernsting.io">nils@ernsting.io</a>
            </div>
          </div>
          <div className="contact-item">
            <span>Address</span>
            <div>
              Erikastraße 87
              <br />
              20251 Hamburg
            </div>
          </div>
          <div className="contact-item">
            <span>vCard</span>
            <div>
              <a
                href="/assets/Nils_Ernsting.vcf"
                className="vcard"
                title="Download vCard"
              >
                <VCard />
              </a>
            </div>
          </div>
        </div>
        <div className="flex-row space-center links">
          <a href="https://www.linkedin.com/in/nils-ernsting/">
            <LinkedIn />
          </a>
          <a href="https://github.com/nernsting">
            <Github />
          </a>
        </div>
      </div>
    </div>
  );
}

export default ContactImprint;
