import educations from "../data/educations.json";
import { ReactComponent as EducationLogo } from "../svg/education.svg";

const Education = () => {
  return (
    <div className="section" id="education">
      <div className="header">
        <EducationLogo />
        Education
      </div>
      {educations.map((education) => {
        return (
          <div className="col pt-40" key={education.diploma}>
            <div className="content-period pt-1">
              {education.period}
              {education.logo ? (
                <img
                  className={
                    education.logoBackground
                      ? "company-logo company-logo-background"
                      : "company-logo"
                  }
                  src={education.logo}
                  alt={education.subject}
                />
              ) : (
                ""
              )}
            </div>
            <div className="content">
              <div className="col flex-row flex-mobile-column space-between pb-10">
                <div className="text-bold text-large">{education.subject}</div>
                <div className="text-thin pt-1">{education.diploma}</div>
                <div className="text-bold pt-1">@ {education.university}</div>
              </div>
              {education.description}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Education;
