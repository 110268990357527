import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./sections/Header";
import Home from "./sections/Home";
import Error from "./sections/Error";
import Imprint from "./sections/Imprint";
import Footer from "./sections/Footer";
import Theme from "./provider/ThemeProvider";

function App() {
  return (
    <Theme>
      <Router>
        <Header />
        <Routes>
          <Route path="*" element={<Error />}></Route>
          <Route path="/" element={<Home />}></Route>
          <Route path="/imprint" element={<Imprint />}></Route>
        </Routes>
        <Footer />
      </Router>
    </Theme>
  );
}

export default App;
